// eslint-disable-next-line import/prefer-default-export
export function initialize(currentUser, currentOrgId) {
  window.pendo.initialize({
    visitor: {
      id: `${process.env.REACT_APP_ENVIRONMENT}_${currentUser.id}`,
      email: currentUser.loginEmail,
      type: currentUser.typeId,
      organizationId: currentOrgId,
      environment: process.env.REACT_APP_ENVIRONMENT,
    },
    account: {
      id: currentOrgId,
    },
    disableGuides: !!window?.Cypress,
  });
}
